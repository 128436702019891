import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { WagmiProvider, createConfig, http } from "wagmi";
import { walletConnect } from "wagmi/connectors";
import { mainnet, polygonAmoy } from "wagmi/chains";
import { BrowserRouter } from "react-router-dom";
import createSagaMiddleware from "redux-saga";
import { applyMiddleware, compose, createStore } from "redux";
import rootReducer from "./store/reducer";
import { watchDashboard, watchToken } from "./store/sagas";
import { Provider } from "react-redux";

const projectId = "8c963e6e044549c04fdc38419fd26a01";

export const config = createConfig({
  chains: [polygonAmoy, mainnet],
  connectors: [walletConnect({ projectId })],
  transports: {
    [mainnet.id]: http(),
    [polygonAmoy.id]: http(),
  },
});
const queryClient = new QueryClient();

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const sagaMiddleware = createSagaMiddleware();

const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(sagaMiddleware))
);

sagaMiddleware.run(watchDashboard);
sagaMiddleware.run(watchToken);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <WagmiProvider config={config}>
        <QueryClientProvider client={queryClient}>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </QueryClientProvider>
      </WagmiProvider>
    </Provider>
  </React.StrictMode>
);
