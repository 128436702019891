/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import { Button, Input, Modal } from "antd";
import { ArrowDownOutlined, DownOutlined } from "@ant-design/icons";
import {
  LinkTokenAbi,
  SolulabLiquidityTokenAbi,
  SolulabTokenAbi,
  UsdcTokenAbi,
  WETHTokenAbi,
} from "../contract";
import { waitForTransactionReceipt } from "viem/actions";
import { getClient } from "@wagmi/core";
import { http, createPublicClient } from "viem";
import { useWriteContract, useReadContract } from "wagmi";
import { config } from "..";
import {
  LinkTokenAddress,
  SLTTokenAddress,
  SolulabLiquidityAddress,
  UsdcTokenAddress,
  WETHTokenAddress,
} from "../contract/address";
import { polygonAmoy } from "wagmi/chains";
import { convertToDecimal } from "../helper/func";
import SuccessBox from "./Success";
import { useDispatch, useSelector } from "react-redux";
import { AddSwapTransaction, tokenStats } from "../store/actions";
const abi = {
  [UsdcTokenAddress]: UsdcTokenAbi,
  [LinkTokenAddress]: LinkTokenAbi,
  [SLTTokenAddress]: SolulabTokenAbi,
  [WETHTokenAddress]: WETHTokenAbi,
};

function Swap(props) {
  const dispatch = useDispatch();
  const { isLoading: tokenLoading, tokenData } = useSelector(
    (state) => state.token
  );
  // const [slippage, setSlippage] = useState(2.5);
  const [tokenOneAmount, setTokenOneAmount] = useState(null);
  const [tokenTwoAmount, setTokenTwoAmount] = useState(null);
  const [tokenOne, setTokenOne] = useState(null);
  const [tokenTwo, setTokenTwo] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [swapSuccess, setSwapSuccess] = useState(false);
  const [changeToken, setChangeToken] = useState(1);
  const [isLoading, setIsLoading] = useState();
  const [errorMessage, setErrorMessage] = useState("");
  const { writeContractAsync } = useWriteContract();

  // function handleSlippageChange(e) {
  //   setSlippage(e.target.value);
  // }

  function changeAmount(e) {
    if (e.target.value) {
      setTokenOneAmount(e.target.value);
    } else {
      setTokenOneAmount(null);
      setTokenTwoAmount(null);
    }
  }

  function switchTokens() {
    const oneAmount = tokenOneAmount;
    const twoAmount = tokenTwoAmount;
    setTokenOneAmount(twoAmount);
    setTokenTwoAmount(oneAmount);
    const one = tokenOne;
    const two = tokenTwo;
    setTokenOne(two);
    setTokenTwo(one);
  }

  function openModal(asset) {
    setChangeToken(asset);
    setIsOpen(true);
  }

  const debounceTimeout = useRef(null);
  function modifyToken(i) {
    setTokenOneAmount(null);
    setTokenTwoAmount(null);
    if (changeToken === 1) {
      setTokenOne(tokenData[i]);
      setTokenTwo(tokenData[i]?.relatedTokens[0]);
    } else {
      setTokenTwo(
        tokenData.find((a) => a._id === tokenOne._id).relatedTokens[i]
      );
    }
    setIsOpen(false);
  }
  const { data, isPending } = useReadContract({
    abi: abi[tokenOne?.address],
    address: tokenOne?.address,
    functionName: "allowance",
    args: [props.address, SolulabLiquidityAddress],
  });

  const handleTransfer = async () => {
    setIsLoading(true);
    const tkn = Number(+tokenOneAmount);
    // eslint-disable-next-line no-undef
    let totalPrice = BigInt(convertToDecimal(+tkn, +tokenOne?.decimals));
    setErrorMessage("");

    try {
      const client = getClient(config);
      if (!(totalPrice <= data)) {
        const allowanceHash = await writeContractAsync({
          abi: abi[tokenOne?.address],
          address: tokenOne?.address,
          functionName: "approve",
          args: [
            SolulabLiquidityAddress,
            convertToDecimal(+tkn, +tokenOne?.decimals),
          ],
        });
        await waitForTransactionReceipt(client, {
          confirmations: 1,
          hash: allowanceHash,
        });
      }
      const txHash = await writeContractAsync({
        abi: SolulabLiquidityTokenAbi,
        address: SolulabLiquidityAddress,
        functionName: "swapTokens",
        args: [
          tokenOne?.address,
          tokenTwo?.address,
          convertToDecimal(+tkn, +tokenOne?.decimals),
        ],
      });
      const res = await waitForTransactionReceipt(client, {
        confirmations: 1,
        hash: txHash,
      });
      if (res.status === "success") {
        setSwapSuccess(true);

        const data = {
          transactionHash: res.transactionHash,
          accountWalletPublicAddress: props.address,
          token1: tokenOne._id,
          token2: tokenTwo._id,
          amountToken2: tokenTwoAmount,
          amountToken1: tokenOneAmount,
        };

        dispatch(AddSwapTransaction(data));

        setTimeout(() => {
          setSwapSuccess(false);
          setTokenOneAmount(null);
          setTokenTwoAmount(null);
        }, 6000);
      }
    } catch (error) {
      setErrorMessage(error?.shortMessage || error?.message);
      console.log({ error });
    } finally {
      setIsLoading(false);
    }
  };

  // useEffect(() => {
  //   if (debounceTimeout.current) {
  //     clearTimeout(debounceTimeout.current);
  //   }

  //   debounceTimeout.current = setTimeout(() => {
  //     const getAmount = async () => {
  //       try {
  //         const result = await createPublicClient({
  //           chain: polygonAmoy,
  //           transport: http(),
  //         }).readContract({
  //           abi: SolulabLiquidityTokenAbi,
  //           address: SolulabLiquidityAddress,
  //           functionName: "getAmountsOut",
  //           args: [
  //             tokenOne?.address,
  //             tokenTwo?.address,
  //             convertToDecimal(tokenOneAmount, +tokenOne?.decimals)?.toString(),
  //           ],
  //         });

  //         setTokenTwoAmount(
  //           Number(
  //             Number(Number(result) / Math.pow(10, tokenTwo?.decimals)).toFixed(
  //               6
  //             )
  //           )
  //         );
  //       } catch (err) {
  //         console.log(err);
  //         // setTokenTwoAmount(0);
  //       }
  //     };

  //     getAmount();
  //   }, 100); // Adjust debounce delay as needed (300ms in this case)

  //   // Clean up timeout on unmount or before setting a new one
  //   return () => {
  //     if (debounceTimeout.current) {
  //       clearTimeout(debounceTimeout.current);
  //     }
  //   };
  // }, [tokenOneAmount]);

  useEffect(() => {
    if (!tokenOneAmount) {
      setTokenTwoAmount(null);
    }
  }, [tokenOneAmount, tokenTwoAmount]);
  useEffect(() => {
    const getAmount = async () => {
      try {
        const result = await createPublicClient({
          chain: polygonAmoy,
          transport: http(),
        }).readContract({
          abi: SolulabLiquidityTokenAbi,
          address: SolulabLiquidityAddress,
          functionName: "getAmountsOut",
          args: [
            tokenOne?.address,
            tokenTwo?.address,
            convertToDecimal(tokenOneAmount, +tokenOne?.decimals)?.toString(),
          ],
        });

        setTokenTwoAmount(
          Number(
            Number(Number(result) / Math.pow(10, tokenTwo?.decimals)).toFixed(6)
          )
        );
        return result;
      } catch (err) {
        console.log(err);
        return 0;
      }
    };
    getAmount();
  }, [tokenOneAmount]);
  useEffect(() => {
    dispatch(tokenStats());
  }, []);
  // const settings = (
  //   <>
  //     <div>Slippage Tolerance</div>
  //     <div>
  //       <Radio.Group value={slippage} onChange={handleSlippageChange}>
  //         <Radio.Button value={0.5}>0.5%</Radio.Button>
  //         <Radio.Button value={2.5}>2.5%</Radio.Button>
  //         <Radio.Button value={5}>5.0%</Radio.Button>
  //       </Radio.Group>
  //     </div>
  //   </>
  // );

  useEffect(() => {
    setTokenOne(tokenData[0]);
    setTokenTwo(tokenData[1]);
  }, [tokenData]);
  if (tokenLoading) return <div className="loader"></div>;
  return (
    <>
      <Modal
        open={isOpen}
        footer={null}
        onCancel={() => setIsOpen(false)}
        title="Select a token"
      >
        {changeToken === 1 ? (
          <div className="modalContent">
            {tokenData?.map((e, i) => {
              return (
                <div
                  className="tokenChoice"
                  key={i}
                  onClick={() => modifyToken(i)}
                >
                  <img src={e.img} alt={e.ticker} className="tokenLogo" />
                  <div className="tokenChoiceNames">
                    <div className="tokenName">{e.name}</div>
                    <div className="tokenTicker">{e.ticker}</div>
                  </div>
                </div>
              );
            })}
          </div>
        ) : (
          <div className="modalContent">
            {tokenData
              .find((a) => a._id === tokenOne._id)
              .relatedTokens?.map((e, i) => {
                return (
                  <div
                    className="tokenChoice"
                    key={i}
                    onClick={() => modifyToken(i)}
                  >
                    <img src={e.img} alt={e.ticker} className="tokenLogo" />
                    <div className="tokenChoiceNames">
                      <div className="tokenName">{e.name}</div>
                      <div className="tokenTicker">{e.ticker}</div>
                    </div>
                  </div>
                );
              })}
          </div>
        )}
      </Modal>
      {swapSuccess ? (
        <SuccessBox
          tokenOne={tokenOne}
          tokenTwo={tokenTwo}
          tokenTwoAmount={tokenTwoAmount}
          tokenOneAmount={tokenOneAmount}
        />
      ) : (
        <div className="tradeBox" aria-disabled={isPending}>
          <div className="tradeBoxHeader">
            <h4>Swap</h4>
            {/* <Popover
            content={settings}
            title="Settings"
            trigger="click"
            placement="bottomRight"
          >
            <SettingOutlined className="cog" />
          </Popover> */}
          </div>
          <div className="inputs">
            <Input
              placeholder="0"
              value={tokenOneAmount}
              onChange={changeAmount}
              type="number"
            />
            <Input placeholder="0" value={tokenTwoAmount} disabled />
            <div className="switchButton" onClick={switchTokens}>
              <ArrowDownOutlined className="switchArrow" />
            </div>
            <div className="assetOne" onClick={() => openModal(1)}>
              <img
                src={tokenOne?.img}
                alt="assetOneLogo"
                className="assetLogo"
              />
              {tokenOne?.ticker}
              <DownOutlined />
            </div>
            <div className="assetTwo" onClick={() => openModal(2)}>
              <img
                src={tokenTwo?.img}
                alt="assetOneLogo"
                className="assetLogo"
              />
              {tokenTwo?.ticker}
              <DownOutlined />
            </div>
          </div>
          {tokenOneAmount && (
            <div className="errorBox">
              Platform Fee: {(tokenOneAmount * 0.3) / 100} (0.3%) of total
              amount
            </div>
          )}
          {errorMessage && (
            <div className="errorBox">ErrorMessage: {errorMessage}</div>
          )}
          <Button
            className="swapButton"
            disabled={isLoading || !tokenOneAmount || !tokenTwoAmount}
            onClick={handleTransfer}
          >
            {isLoading ? "Swaping..." : "Swap"}
          </Button>
        </div>
      )}
    </>
  );
}

export default Swap;
