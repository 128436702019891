/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable import/prefer-default-export */
import { put } from "redux-saga/effects";
import axiosMain from "../../../http/axios/axios_main";

import {
  dashboardStatsFail,
  dashboardStatsStart,
  dashboardStatsSuccess,
  getDashboardDetailsFail,
  getDashboardDetailsStart,
  getDashboardDetailsSuccess,
} from "../../actions/dashboard/dashboard";

export function* dashboardStatsSaga(action) {
  yield put(dashboardStatsStart());
  try {
    const response = yield axiosMain.get(`marketvalue`);
    if (response.status === 200) {
      yield put(dashboardStatsSuccess(response.data.data));
    } else {
      yield put(dashboardStatsFail("Something went wrong! Please try again."));
    }
  } catch (error) {
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(dashboardStatsFail(error.response.data.msg));
      } else if (
        error.response.data.msg !== undefined &&
        error.response.data.msg !== "" &&
        typeof error.response.data.msg === "string"
      ) {
        yield put(dashboardStatsFail(error.response.data.msg));
      } else {
        yield put(
          dashboardStatsFail("Something went wrong! Please try again.")
        );
      }
    } else {
      yield put(dashboardStatsFail("Something went wrong! Please try again."));
    }
  }
}

export function* getDashboardDetailsSaga() {
  // const { data } = action.payload;

  yield put(getDashboardDetailsStart());
  try {
    const response = yield axiosMain.get(`/dashboard`);
    if (response.status === 200) {
      yield put(getDashboardDetailsSuccess(response.data));
    } else {
      yield put(
        getDashboardDetailsFail("Something went wrong! Please try again.")
      );
    }
  } catch (error) {
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(getDashboardDetailsFail(error.response.data.msg));
      } else if (
        error.response.data.msg !== undefined &&
        error.response.data.msg !== "" &&
        typeof error.response.data.msg === "string"
      ) {
        yield put(getDashboardDetailsFail(error.response.data.msg));
      } else {
        yield put(
          getDashboardDetailsFail("Something went wrong! Please try again.")
        );
      }
    } else {
      yield put(
        getDashboardDetailsFail("Something went wrong! Please try again.")
      );
    }
  }
}
