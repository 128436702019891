import React, { useState } from "react";
import Eth from "../eth.svg";
import { Link } from "react-router-dom";
import ConnectWalletModal from "./connectwallet/connectwallet";
import { SOLULAB_LOGO } from "../assets/images";

function Header(props) {
  const [isConnect, setIsconnect] = useState(false);

  const { address, isConnected } = props;

  return (
    <header>
      <div className="leftH">
        <img src={SOLULAB_LOGO} alt="logo" className="logo" />
        <Link to="/" className="link">
          <div className="headerItem">Swap</div>
        </Link>
        <Link to="/tokens" className="link">
          <div className="headerItem">Tokens</div>
        </Link>
        <Link to="/pool" className="link">
          <div className="headerItem">Pool</div>
        </Link>
      </div>
      <div className="rightH">
        <div className="headerItem">
          <img src={Eth} alt="eth" className="eth" />
          Polygon Amoy
        </div>
        <div
          className="connectButton"
          onClick={() => {
            !isConnected && setIsconnect(!isConnect);
          }}
        >
          {isConnected
            ? address.slice(0, 4) + "..." + address.slice(38)
            : "Connect"}
        </div>
      </div>
      {isConnect && (
        <ConnectWalletModal
          title="Connect Wallet"
          open={isConnect}
          setIsconnect={setIsconnect}
        />
      )}
    </header>
  );
}

export default Header;
