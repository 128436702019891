import { TextField } from "@mui/material";
import React from "react";

const Login = ({ setIsLogin }) => {
  const [value, setValue] = React.useState(0);

  const handleSubmit = () => {
    if (value === process.env.REACT_APP_LOGIN_SECRET) {
      setIsLogin(true);
    }
  };
  return (
    <div className="createBox">
      <TextField
        id="outlined-basic"
        label="Password"
        variant="outlined"
        onChange={(e) => setValue(e.target.value)}
      />

      <div className="swapButton" onClick={handleSubmit}>
        Submit
      </div>
    </div>
  );
};

export default Login;
