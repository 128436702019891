/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import AddLiquidity from "./Model/AddLiquidity";
import { Box } from "@mui/material";
import { Table, Button } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { GetPoolList } from "../store/actions";
import { formatNumber } from "../helper/func";
import RemoveLiquidity from "./Model/RemoveLiquidity";
import { useAccount } from "wagmi";

export default function Pool() {
  const { address } = useAccount();

  const [isOpen, setIsOpen] = React.useState({
    open: false,
    remove: false,
    data: null,
  });
  const dispatch = useDispatch();
  const { isPoolDataFetching, poolData } = useSelector((state) => state.token);

  React.useEffect(() => {
    dispatch(GetPoolList());
  }, []);
  const columns = [
    {
      key: "id",
      dataIndex: "id",

      title: "#",
    },
    {
      key: "Pool",
      dataIndex: "Pool",

      title: "Pool",

      resizable: true,
      render: (_, record) => (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "6px",
            fontWeight: 500,
          }}
        >
          <div className="circle-container">
            <img
              className="half-circle"
              alt="noLogo"
              src={record.token1Details.img}
            />
            <img
              className="half-circle"
              alt="noLogo"
              src={record.token2Details.img}
            />
          </div>{" "}
          {record?.token1Details.ticker}/{record.token2Details.ticker}
        </Box>
      ),
    },

    {
      key: "CountOfTransaction",
      dataIndex: "CountOfTransaction",

      title: "Transactions",
      align: "center",

      resizable: true,
    },
    {
      key: "TVL",
      dataIndex: "TVL",
      align: "right",
      title: "TVL",
      render: (_, record) => (
        <>$ {formatNumber(record?.liquidityDetails.all.totalToken1Volume)}</>
      ),
    },
    {
      key: "1_day_volume",
      dataIndex: "1_day_volume",
      align: "right",

      title: "1 day volume",

      resizable: true,
      render: (_, record) => (
        <>
          $ {formatNumber(record?.liquidityDetails.last1Day.totalToken1Volume)}
        </>
      ),
    },
    {
      key: "7_day_apy",
      dataIndex: "7_day_apy",
      align: "right",

      title: "7 day volume",
      render: (_, record) => (
        <>
          $ {formatNumber(record?.liquidityDetails.last7Days.totalToken1Volume)}
        </>
      ),
    },
    {
      key: "",
      dataIndex: "",
      title: "Action",
      align: "center",
      render: (_, record) => (
        <Box
          sx={{
            display: "flex ",
            gap: "5px",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Button
            style={{ background: "#5981f3" }}
            type="primary"
            disabled={!address}
            onClick={() => setIsOpen({ open: true, data: record })}
          >
            Add Liquidity
          </Button>
          <Button
            style={{ background: "#ed5a5a" }}
            disabled={!address}
            type="primary"
            onClick={() => setIsOpen({ remove: true, data: record })}
          >
            Remove Liquidity
          </Button>
        </Box>
      ),
    },
  ];
  const onCancel = () => {
    setIsOpen({ open: false, data: null });
  };

  return (
    <div className="TokenList">
      <Table
        columns={columns}
        pagination={false} // Disable pagination
        dataSource={poolData.map((item, i) => ({ id: i + 1, ...item }))}
        loading={isPoolDataFetching}
      />
      {isOpen.open && <AddLiquidity onCancel={onCancel} isOpen={isOpen} />}
      {isOpen.remove && <RemoveLiquidity onCancel={onCancel} isOpen={isOpen} />}
    </div>
  );
}
