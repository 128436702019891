import React from "react";
import { ARROW_ICON, CHECK_ICON } from "../assets/images";

const SuccessBox = ({
  tokenOne,
  tokenTwo,
  tokenTwoAmount,
  tokenOneAmount,
  msg = "Token Swap Successfully",
}) => {
  return (
    <div className="tradeBox successBox">
      <div style={{ marginTop: 50 }}>
        <img src={CHECK_ICON} alt="me" width="80px" />

        <div className="successBoxHeader">{msg}</div>
      </div>

      <div className="tokenDetailsBox">
        <div className="tokenDetails">
          <div className="successassetOne">
            <img src={tokenOne.img} alt="assetOneLogo" className="assetLogo" />
            {tokenOne.ticker}
          </div>
          <span className="amount">{tokenOneAmount || 0}</span>
        </div>
        <img src={ARROW_ICON} alt="assetOneLogo" width={50} />

        <div className="tokenDetails">
          <div className="successassetOne">
            <img src={tokenTwo.img} alt="assetOneLogo" className="assetLogo" />
            {tokenTwo.ticker}
          </div>
          <span className="amount">{tokenTwoAmount || 0}</span>
        </div>
      </div>
    </div>
  );
};

export default SuccessBox;
