export const DASHBOARD_STATS_SAGA = "DASHBOARD_STATS_SAGA";
export const DASHBOARD_STATS_START = "DASHBOARD_STATS_START";
export const DASHBOARD_STATS_SUCCESS = "DASHBOARD_STATS_SUCCESS";
export const DASHBOARD_STATS_FAIL = "DASHBOARD_STATS_FAIL";

export const GET_DASHBOARD_DETAILS_SAGA = "GET_DASHBOARD_DETAILS_SAGA";
export const GET_DASHBOARD_DETAILS_START = "GET_DASHBOARD_DETAILS_START";
export const GET_DASHBOARD_DETAILS_SUCCESS = "GET_DASHBOARD_DETAILS_SUCCESS";
export const GET_DASHBOARD_DETAILS_FAIL = "GET_DASHBOARD_DETAILS_FAIL";

export const SET_DEFAULT = "SET_DEFAULT";
