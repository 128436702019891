/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import { Button, Modal } from "antd";
import React, { useState } from "react";
import "./model.css";
import { waitForTransactionReceipt } from "viem/actions";
import { useAccount, useReadContract, useWriteContract } from "wagmi";
import { config } from "../..";
import { SolulabLiquidityAddress } from "../../contract/address";
import {
  PoolAbi,
  SolulabLiquidityTokenAbi,
  UsdcTokenAbi,
} from "../../contract";
import { getClient } from "@wagmi/core";
import { CHECK_ICON } from "../../assets/images";
import { useDispatch } from "react-redux";
import { AddLiquidityTransaction } from "../../store/actions";

const RemoveLiquidity = ({ isOpen, onCancel }) => {
  const [isLoading, setIsLoading] = useState();
  const { remove, data } = isOpen;
  const dispatch = useDispatch();
  const { address } = useAccount();
  const [tokenOne, setTokenOne] = useState(data?.token1Details);
  const [tokenTwo, setTokenTwo] = useState(data?.token2Details);
  const [addSuccess, setAddSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const { writeContractAsync } = useWriteContract();
  const { data: balanceData, isPending } = useReadContract({
    functionName: "balanceOf",
    abi: PoolAbi,
    args: [address],
    address: data.pairContract,
  });

  const handleTransfer = async () => {
    setIsLoading(true);
    // eslint-disable-next-line no-undef
    setErrorMessage("");
    try {
      const client = getClient(config);

      const allowanceHash2 = await writeContractAsync({
        abi: PoolAbi,
        address: data.pairContract,
        functionName: "approve",
        args: [SolulabLiquidityAddress, balanceData],
      });
      await waitForTransactionReceipt(client, {
        confirmations: 1,
        hash: allowanceHash2,
      });

      const txHash = await writeContractAsync({
        abi: SolulabLiquidityTokenAbi,
        address: SolulabLiquidityAddress,
        functionName: "removeLiquidity",
        args: [tokenOne?.address, tokenTwo?.address, balanceData],
      });
      const res = await waitForTransactionReceipt(client, {
        confirmations: 1,
        hash: txHash,
      });
      if (res.status === "success") {
        const apiData = {
          transactionHash: res.transactionHash,
          pool_id: data._id,
          accountWalletPublicAddress: address,
          token1: tokenOne._id,
          token2: tokenTwo._id,
          type: "Remove",
        };

        dispatch(AddLiquidityTransaction(apiData));
        setAddSuccess(true);
        setTimeout(() => {
          setAddSuccess(false);
          handleClose();
        }, 6000);
      }
    } catch (error) {
      setErrorMessage(error?.shortMessage || error?.message);
      // setTimeout(() => {
      //   setErrorMessage("");
      // }, 3000);
      console.log({ error });
    } finally {
      setIsLoading(false);
    }
  };

  const handleClose = () => {
    onCancel();
  };
  return (
    <Modal
      open={remove}
      footer={null}
      onCancel={handleClose}
      title="Remove liquidity"
      maskClosable={false}
      style={{ paddingBottom: 0 }}
    >
      {addSuccess ? (
        <div
          style={{
            padding: "50px 0",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            gap: "10px",
          }}
        >
          <img src={CHECK_ICON} alt="me" width="80px" />

          <div className="successBoxHeader">Liquidity Removed Successfully</div>
        </div>
      ) : isPending ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            padding: "100px 0",
          }}
        >
          <div className="loader"></div>
        </div>
      ) : (
        <div className="modalContent" style={{ padding: "16px " }}>
          <div className="tokenDetails">
            <div className="token">
              <img
                src={data?.token1Details.img}
                alt="assetOneLogo"
                className="assetLogo"
              />
              {data?.token1Details.ticker}
            </div>
            <div className="token">
              <img
                src={data?.token2Details.img}
                alt="assetOneLogo"
                className="assetLogo"
              />
              {data?.token2Details.ticker}
            </div>
          </div>
          <div className="inputs">
            {errorMessage ||
              (Number(balanceData) < 1 && (
                <div className="errorBox">
                  ErrorMessage:{" "}
                  {Number(balanceData) < 1
                    ? "Not have any Liquidity to remove."
                    : errorMessage}
                </div>
              ))}
            <Button
              className="swapButton"
              disabled={isLoading || Number(balanceData) < 1}
              onClick={handleTransfer}
              style={{ marginBottom: "8px" }}
            >
              {isLoading ? "Removing..." : "Remove"}
            </Button>
          </div>
        </div>
      )}
    </Modal>
  );
};

export default RemoveLiquidity;
