import { TextField } from "@mui/material";
import "./Create.css";
import React from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { AddToken } from "../../store/actions";
import { Button } from "antd";

const CreateToken = () => {
  const { isTokenAddLoading, addTokenErr } = useSelector(
    (state) => state.token
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const dispatch = useDispatch();
  const onSubmit = (data) => dispatch(AddToken(data));
  return (
    <div className="createBox">
      <TextField
        error={errors.name}
        id="outlined-basic"
        label="Name"
        variant="outlined"
        placeholder="Solulab Token"
        {...register("name", { required: true })}
      />
      <TextField
        error={errors.address}
        id="outlined-basic"
        label="Address"
        {...register("address", { required: true })}
        variant="outlined"
        placeholder="0xa00000000000000000000000000000000000000"
      />
      <TextField
        error={errors.ticker}
        id="outlined-basic"
        label="Ticker"
        {...register("ticker", { required: true })}
        variant="outlined"
        placeholder="SLT"
      />
      <TextField
        error={errors.decimals}
        id="outlined-basic"
        placeholder="18"
        {...register("decimals", { required: true })}
        label="Decimals"
        variant="outlined"
      />
      <TextField
        error={errors.img}
        {...register("img", { required: true })}
        id="outlined-basic"
        label="Img Url"
        variant="outlined"
        placeholder="https://img.com"
      />
      {addTokenErr && <div className="emsg">ErrorMessage: {addTokenErr}</div>}

      <Button className="swapButton" onClick={handleSubmit(onSubmit)}>
        {isTokenAddLoading ? "Adding..." : "Add"}
      </Button>
    </div>
  );
};

export default CreateToken;
