import { combineReducers } from "redux";

import dashboard from "./dashboard/dashboard";
import token from "./Token/Token";

const allReducers = combineReducers({
  dashboard,
  token,
});

const rootReducer = (state, action) => {
  if (action.type === "RESET_APP") {
    // eslint-disable-next-line no-param-reassign
    state = undefined;
  }

  return allReducers(state, action);
};

export default rootReducer;
