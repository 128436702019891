import { Button, Modal } from "antd";
import "./Create.css";
import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { PoolAdd } from "../../store/actions";
import { DownOutlined } from "@ant-design/icons";
import { ARROW_ICON } from "../../assets/images";
import { useAccount, useReadContract, useWriteContract } from "wagmi";
import {
  CreatePairAbi,
  LinkTokenAbi,
  SolulabLiquidityTokenAbi,
  SolulabTokenAbi,
  UsdcTokenAbi,
  WETHTokenAbi,
} from "../../contract";
import {
  FactoryAddress,
  LinkTokenAddress,
  SLTTokenAddress,
  SolulabLiquidityAddress,
  UsdcTokenAddress,
  WETHTokenAddress,
} from "../../contract/address";
import { getClient } from "@wagmi/core";
import { config } from "../..";
import { waitForTransactionReceipt } from "viem/actions";
const dummyAdd = "0x0000000000000000000000000000000000000000";
const abi = {
  [UsdcTokenAddress]: UsdcTokenAbi,
  [LinkTokenAddress]: LinkTokenAbi,
  [SLTTokenAddress]: SolulabTokenAbi,
  [WETHTokenAddress]: WETHTokenAbi,
};
const CreatePool = () => {
  const { isTokenAddLoading, isLoading, poolErr, tokenData } = useSelector(
    (state) => state.token
  );
  const [isliqLoading, setIsLiqLoading] = useState();
  const { writeContractAsync } = useWriteContract();
  const { address } = useAccount();

  const [pairchek, setPairCheck] = useState(null);
  const [tokenOne, setTokenOne] = useState(null);
  const [tokenTwo, setTokenTwo] = useState(null);
  const [changeToken, setChangeToken] = useState(1);
  const [isOpen, setIsOpen] = useState(false);

  const dispatch = useDispatch();

  function openModal(asset) {
    setChangeToken(asset);
    setIsOpen(true);
  }

  const { data, status, isPending } = useReadContract({
    abi: SolulabLiquidityTokenAbi,
    address: SolulabLiquidityAddress,
    functionName: "getPair",
    args: [tokenOne?.address, tokenTwo?.address],
  });
  function modifyToken(i) {
    if (changeToken === 1) {
      setTokenOne(tokenData[i]);
      setTokenTwo(tokenData.filter((a) => a._id !== tokenData[i]._id)[0]);
    } else {
      setTokenTwo(tokenData.filter((a) => a._id !== tokenOne._id)[i]);
    }
    setIsOpen(false);
  }
  useEffect(() => {
    setTokenOne(tokenData[0]);
    setTokenTwo(tokenData[1]);
  }, [tokenData]);
  useEffect(() => {
    if (status === "success" && data === dummyAdd) {
      setPairCheck(true);
    } else {
      setPairCheck(false);
    }
  }, [status]);

  const handleTransfer = async () => {
    setIsLiqLoading(true);
    // eslint-disable-next-line no-undef

    try {
      const client = getClient(config);
      const allowanceHash = await writeContractAsync({
        abi: CreatePairAbi,
        address: FactoryAddress,
        functionName: "createPair",
        args: [tokenOne.address, tokenTwo.address],
      });
      const data = await waitForTransactionReceipt(client, {
        confirmations: 1,
        hash: allowanceHash,
      });

      if (data.status === "success") {
        const newData = {
          token1_Address: tokenOne.address,
          token2_Address: tokenTwo.address,
          transactionHash: data.transactionHash,
        };
        dispatch(PoolAdd(newData));
      }
      setIsLiqLoading(false);
    } catch (error) {
      setIsLiqLoading(false);
      // setTimeout(() => {
      //   setErrorMessage("");
      // }, 3000);
      console.log({ error });
    } finally {
      setIsLiqLoading(false);
    }
  };

  return (
    <>
      {isLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            padding: "100px 0",
          }}
        >
          <div className="loader"></div>
        </div>
      ) : (
        <div className="createBox">
          <div className="Box">
            <div className="token" onClick={() => openModal(1)}>
              <img
                src={tokenOne?.img}
                alt="assetOneLogo"
                className="assetLogo"
              />
              {tokenOne?.ticker}
              <DownOutlined />
            </div>{" "}
            <img src={ARROW_ICON} alt="assetOneLogo" className="assetLogo" />
            <div className="token" onClick={() => openModal(2)}>
              <img
                src={tokenTwo?.img}
                alt="assetOneLogo"
                className="assetLogo"
              />
              {tokenTwo?.ticker}
              <DownOutlined />
            </div>
          </div>
          {!isPending && !pairchek && (
            <div className="emsg">Pair Already Created</div>
          )}
          {poolErr && <div className="emsg">ErrorMessage: {poolErr}</div>}
          <Button
            className="swapButton"
            onClick={() => handleTransfer()}
            disabled={isPending || !pairchek}
          >
            {isPending || isliqLoading || isTokenAddLoading ? (
              <div className="spin-loader"></div>
            ) : (
              "Add"
            )}
          </Button>
        </div>
      )}
      <Modal
        open={isOpen}
        footer={null}
        sx={{ width: 400 }}
        onCancel={() => setIsOpen(false)}
        title="Select a token"
      >
        {changeToken === 1 ? (
          <div className="modalContent">
            {tokenData?.map((e, i) => {
              return (
                <div
                  className="tokenChoice"
                  key={i}
                  onClick={() => modifyToken(i)}
                >
                  <img src={e.img} alt={e.ticker} className="tokenLogo" />
                  <div className="tokenChoiceNames">
                    <div className="tokenName">{e.name}</div>
                    <div className="tokenTicker">{e.ticker}</div>
                  </div>
                </div>
              );
            })}
          </div>
        ) : (
          <div className="modalContent">
            {tokenData
              .filter((a) => a._id !== tokenOne._id)
              .map((e, i) => {
                return (
                  <div
                    className="tokenChoice"
                    key={i}
                    onClick={() => modifyToken(i)}
                  >
                    <img src={e.img} alt={e.ticker} className="tokenLogo" />
                    <div className="tokenChoiceNames">
                      <div className="tokenName">{e.name}</div>
                      <div className="tokenTicker">{e.ticker}</div>
                    </div>
                  </div>
                );
              })}
          </div>
        )}
      </Modal>
    </>
  );
};

export default CreatePool;
