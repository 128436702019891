import React, { useEffect } from "react";
import "./Create.css";
import { Box, Tab, Tabs } from "@mui/material";
import CreateToken from "./CreateToken";
import CreatePool from "./CreatePool";
import Login from "./Login";
import { useDispatch } from "react-redux";
import { tokenStats } from "../../store/actions";

const Create = () => {
  const [value, setValue] = React.useState(0);
  const [isLogin, setIsLogin] = React.useState(false);
  const dispatch = useDispatch();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  useEffect(() => {
    dispatch(tokenStats());
  }, []);
  return (
    <>
      {!isLogin ? (
        <Login setIsLogin={setIsLogin} />
      ) : (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box sx={{ borderBottom: 1, borderColor: "divider", width: "300px" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab
                label="Token Add"
                id="token"
                sx={{
                  color: "white",
                  width: "50%",
                  textTransform: "capitalize",
                }}
              />
              <Tab
                label="Pool Add"
                id="pool"
                sx={{
                  width: "50%",
                  color: "white",
                  textTransform: "capitalize",
                }}
              />
            </Tabs>
          </Box>
          {value === 0 ? <CreateToken /> : <CreatePool />}
        </Box>
      )}
    </>
  );
};

export default Create;
