import { useEffect, useState } from "react";

export const ConnectorButton = ({ connector, name, icon, onClick }) => {
  const [ready, setReady] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        const provider = await connector.getProvider();
        setReady(!!provider);
        if (connector.name === "WalletConnect") {
          document.querySelectorAll("wcm-modal").forEach((elem) => {
            const shadow = elem.shadowRoot;
            const style = document.createElement("style");
            style.textContent = `
              {
                overflow: auto !important;
              }
              .wcm-container {
                // max-height: min(430px,calc(100vh - 30px));
                overflow-y: auto !important;
              }
              .wcm-container::-webkit-scrollbar {
                width: 0px;
              }
              .wcm-container::-webkit-scrollbar-track {
                background: #f1f1f1;
              }
              .wcm-container::-webkit-scrollbar-thumb {
                background: #aaa;
              }
              .wcm-container::-webkit-scrollbar-thumb:hover {
                background: #555;
              }
            `;
            shadow.appendChild(style);
          });
        }
      } catch (err) {
        setReady(false);
      }
    })();
  }, [connector, setReady]);

  return (
    <>
      {ready && (
        <button
          className="wallet_btn"
          disabled={!ready}
          onClick={onClick}
          type="button"
          style={{
            justifyContent: "space-between",
            display: "flex",
            alignItems: "center",
            width: "100%",
          }}
        >
          <span>{name}</span>
          <img src={icon} alt="me" width="32px" />
        </button>
      )}
    </>
  );
};
