import { all, takeLatest } from "redux-saga/effects";
import * as actionLabels from "../actionLabels";
import {
  dashboardStatsSaga,
  getDashboardDetailsSaga,
} from "./dashboard/dashboard";
import {
  AddSwapTransactionSaga,
  tokenListSaga,
  tokenStatsSaga,
  poolListSaga,
  AddLiquiTransactionSaga,
  tokenAddSaga,
  poolAddSaga,
} from "./Token/Token";

// eslint-disable-next-line import/prefer-default-export

export function* watchToken() {
  yield all([takeLatest(actionLabels.TOKEN_STATS_SAGA, tokenStatsSaga)]);
  yield all([takeLatest(actionLabels.TOKEN_LIST_STATS_SAGA, tokenListSaga)]);
  yield all([takeLatest(actionLabels.POOL_ADD_SAGA, poolAddSaga)]);
  yield all([takeLatest(actionLabels.POOL_LIST_STATS_SAGA, poolListSaga)]);
  yield all([takeLatest(actionLabels.TOKEN_ADD_SAGA, tokenAddSaga)]);
  yield all([
    takeLatest(
      actionLabels.ADD_LIQUIDITY_TRANSACTION_SAGA,
      AddLiquiTransactionSaga
    ),
  ]);
  yield all([
    takeLatest(actionLabels.ADD_SWAP_TRANSACTION_SAGA, AddSwapTransactionSaga),
  ]);
}
export function* watchDashboard() {
  yield all([
    takeLatest(actionLabels.DASHBOARD_STATS_SAGA, dashboardStatsSaga),
    takeLatest(
      actionLabels.GET_DASHBOARD_DETAILS_SAGA,
      getDashboardDetailsSaga
    ),
  ]);
}
