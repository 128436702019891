import axios from "axios";

const axiosMain = axios.create({
  baseURL: process.env.REACT_APP_END_POINT_URL,
  headers: {
    "Content-Type": "application/json",
    "ngrok-skip-browser-warning": "69420",
  },
});

export default axiosMain;

export const setBaseUrl = (baseUrl) => {
  axiosMain.defaults.baseURL = baseUrl;
};
