const getZero = (decimal) => {
  return ""?.padEnd(decimal, "0");
};

export const convertToDecimal = (value = "", decimal) => {
  const strValue = value.toString();
  if (strValue?.includes(".")) {
    const strarr = strValue.split(".");
    let resStr = `${strarr[0]}${strarr[1]}${getZero(
      +decimal - +strarr[1].length
    )}`;
    return resStr;
  } else {
    let resStr = `${value}${getZero(+decimal)}`;
    return resStr;
  }
};
export const convertToDecimalString = (value = "", decimal) => {
  const strValue = value.toString();
  const integerPart = strValue.slice(0, -decimal);
  const fractionalPart = strValue.slice(-decimal);

  // Remove trailing zeroes from fractional part
  const cleanedFractionalPart = fractionalPart.replace(/0+$/, "");

  if (cleanedFractionalPart.length > 0) {
    return `${integerPart}.${cleanedFractionalPart}`;
  } else {
    return integerPart;
  }
};

export function formatNumber(number) {
  if (!number) {
    return 0;
  }
  return number?.toLocaleString("en-US", {
    maximumFractionDigits: 2,
    notation: "compact",
    compactDisplay: "short",
  });
}

export function formatDate(number) {
  const date = new Date(number);
  return date?.toDateString();
}

export function formatDateone(date) {
  const newdate = new Date(date);
  // Extract day, month, and year from the Date object
  const day = newdate.getDate();
  const month = newdate.getMonth() + 1; // Months are zero-indexed, so add 1
  const year = newdate.getFullYear();

  // Format the date as "day/month/year"
  return `${day}/${month}/${year}`;
}
