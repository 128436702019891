/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import "./chart.css";
import { formatDate, formatDateone, formatNumber } from "../../helper/func";
import { useDispatch, useSelector } from "react-redux";
import { dashboardStats } from "../../store/actions";

const TVLChart = () => {
  const [hoverData, setHoverData] = useState(null);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(dashboardStats());
  }, []);

  useEffect(() => {
    // Function to add dynamic classes to x-axis labels
    const addDynamicClasses = () => {
      const labels = document.querySelectorAll(".apexcharts-xaxis-label");
      labels.forEach((label) => {
        const text = label.textContent;
        if (!text.includes("Jan")) {
          //   label.classList.add("displayNone");
        }
      });
    };

    // Add classes when the chart is fully loaded
    setTimeout(() => {
      addDynamicClasses();
    }, 100); // Adjust the timeout if necessary
  }, []);
  const { dashboardData, isLoading } = useSelector((state) => state.dashboard);
  const options = {
    chart: {
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false, // Disable zooming
      },

      animations: {
        enabled: false,
      },

      events: {
        mouseMove: function (
          event,
          chartContext,
          { seriesIndex, dataPointIndex, dataPoint }
        ) {
          if (dataPointIndex < 0) {
            setHoverData(dashboardData?.length - 1);
          } else {
            setHoverData(dataPointIndex);
          }
        },
        mouseLeave: function (
          event,
          chartContext,
          { seriesIndex, dataPointIndex, dataPoint }
        ) {
          setHoverData(null);
        },
      },
    },
    yaxis: {
      labels: {
        show: false,
      },
    },
    xaxis: {
      tickPlacement: "on",
      tickAmount: dashboardData?.length - 5,
      labels: {
        show: true,
        hideOverlappingLabels: true,
        formatter: (value, index) => {
          if (value) {
            const data = new Date(value);
            return formatDateone(data);
          } else {
            return;
          }
          // return data?.includes("Jan") ? data?.split(" ")[1] : "";
        },
        style: {
          colors: "white",
          fontSize: "10px",
          fontFamily: "Helvetica, Arial, sans-serif",
          fontWeight: 400,
        },
      },
      axisTicks: {
        show: false,
      },
      categories: dashboardData?.map((item) => item.createdAt),
    },
    tooltip: {
      show: false,
      style: {
        fontSize: "12px",
        color: "black",
      },
      animation: {
        enabled: false,
      },
      y: {
        formatter: (seriesName) => `$ ${formatNumber(seriesName)}`,
      },
    },
    legend: {
      show: false, // Hide the legend
      labels: {
        colors: ["#fff", "#fff"], // Array of colors for legend items
      },
    },
    grid: {
      yaxis: {
        lines: {
          show: false,
        },
      },
      xaxis: {
        lines: {
          show: false,
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
  };
  const series = [
    {
      name: "Value",
      data: dashboardData?.map((item) => item.finalMarketValue),
      color: "#00E396",
    },
    // {
    //   name: "v2",
    //   color: "#008FFB",
    //   data: data.map((item) => item.v2),
    // },
  ];

  if (isLoading) {
    return (
      <div className="box">
        <div className="loader"></div>
      </div>
    );
  }
  return (
    <>
      <div className="chart-box">
        <div className="chartHeader">TVLChart</div>
        <div className="details-box space-between">
          <div className="details-box">
            <div className="totalval">
              $
              {hoverData
                ? formatNumber(
                    (dashboardData &&
                      dashboardData[hoverData]?.finalMarketValue) ||
                      0
                  )
                : formatNumber(
                    (dashboardData &&
                      dashboardData[dashboardData?.length - 1]
                        ?.finalMarketValue) ||
                      0
                  )}
            </div>
            <div className="time">
              {hoverData
                ? dashboardData &&
                  formatDate(dashboardData[hoverData]?.createdAt)
                : ""}
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "5px",
              marginRight: "40px",
            }}
          >
            {series.map((item) => (
              <div className="lable">
                {hoverData
                  ? `$ ${formatNumber(
                      dashboardData[hoverData]?.["finalMarketValue"]
                    )}`
                  : "Total"}
                <span className="dot" style={{ background: item.color }}></span>
              </div>
            ))}
          </div>
        </div>
      </div>
      <Chart options={options} type="area" series={series} height={350} />
    </>
  );
};

export default TVLChart;
