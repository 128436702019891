import * as actionLabels from "../../actionLabels";

// FOR GET TOKEN STATS
export const tokenStatsStart = (payload) => ({
  type: actionLabels.TOKEN_STATS_START,
  payload,
});

export const tokenStats = (payload) => ({
  type: actionLabels.TOKEN_STATS_SAGA,
  payload,
});

export const tokenStatsSuccess = (payload) => ({
  type: actionLabels.TOKEN_STATS_SUCCESS,
  payload,
});

export const tokenStatsFail = (payload) => ({
  type: actionLabels.TOKEN_STATS_FAIL,
  payload,
});

// FOR ADD LIQUIDITY TRANSACTION
export const AddLiquidityTransactionStart = (payload) => ({
  type: actionLabels.ADD_LIQUIDITY_TRANSACTION_START,
  payload,
});

export const AddLiquidityTransaction = (payload) => ({
  type: actionLabels.ADD_LIQUIDITY_TRANSACTION_SAGA,
  payload,
});

export const AddLiquidityTransactionSuccess = (payload) => ({
  type: actionLabels.ADD_LIQUIDITY_TRANSACTION_SUCCESS,
  payload,
});

export const AddLiquidityTransactionFail = (payload) => ({
  type: actionLabels.ADD_LIQUIDITY_TRANSACTION_FAIL,
  payload,
});

// FOR ADD SEAP TRANSACTION
export const AddSwapTransactionStart = (payload) => ({
  type: actionLabels.ADD_SWAP_TRANSACTION_START,
  payload,
});

export const AddSwapTransaction = (payload) => ({
  type: actionLabels.ADD_SWAP_TRANSACTION_SAGA,
  payload,
});

export const AddSwapTransactionSuccess = (payload) => ({
  type: actionLabels.ADD_SWAP_TRANSACTION_SUCCESS,
  payload,
});

export const AddSwapTransactionFail = (payload) => ({
  type: actionLabels.ADD_SWAP_TRANSACTION_FAIL,
  payload,
});

// FOR TOKEN LIST
export const GetTokenListStart = (payload) => ({
  type: actionLabels.TOKEN_LIST_STATS_START,
  payload,
});

export const GetTokenList = (payload) => ({
  type: actionLabels.TOKEN_LIST_STATS_SAGA,
  payload,
});

export const GetTokenListSuccess = (payload) => ({
  type: actionLabels.TOKEN_LIST_STATS_SUCCESS,
  payload,
});

export const GetTokenListFail = (payload) => ({
  type: actionLabels.TOKEN_LIST_STATS_FAIL,
  payload,
});

// FOR TOKEN LIST
export const GetPoolListStart = (payload) => ({
  type: actionLabels.POOL_LIST_STATS_START,
  payload,
});

export const GetPoolList = (payload) => ({
  type: actionLabels.POOL_LIST_STATS_SAGA,
  payload,
});

export const GetPoolListSuccess = (payload) => ({
  type: actionLabels.POOL_LIST_STATS_SUCCESS,
  payload,
});

export const GetPoolListFail = (payload) => ({
  type: actionLabels.POOL_LIST_STATS_FAIL,
  payload,
});

// FOR TOKEN Add
export const AddTokenStart = (payload) => ({
  type: actionLabels.TOKEN_ADD_START,
  payload,
});

export const AddToken = (payload) => ({
  type: actionLabels.TOKEN_ADD_SAGA,
  payload,
});

export const AddTokenSuccess = (payload) => ({
  type: actionLabels.TOKEN_ADD_SUCCESS,
  payload,
});

export const AddTokenFail = (payload) => ({
  type: actionLabels.TOKEN_ADD_FAIL,
  payload,
});

// FOR TOKEN Add
export const PoolAddStart = (payload) => ({
  type: actionLabels.POOL_ADD_START,
  payload,
});

export const PoolAdd = (payload) => ({
  type: actionLabels.POOL_ADD_SAGA,
  payload,
});

export const PoolAddSuccess = (payload) => ({
  type: actionLabels.POOL_ADD_SUCCESS,
  payload,
});

export const PoolAddFail = (payload) => ({
  type: actionLabels.POOL_ADD_FAIL,
  payload,
});
