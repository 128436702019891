export const TOKEN_STATS_SAGA = "TOKEN_STATS_SAGA";
export const TOKEN_STATS_START = "TOKEN_STATS_START";
export const TOKEN_STATS_SUCCESS = "TOKEN_STATS_SUCCESS";
export const TOKEN_STATS_FAIL = "TOKEN_STATS_FAIL";

export const POOL_LIST_STATS_SAGA = "POOL_LIST_STATS_SAGA";
export const POOL_LIST_STATS_START = "POOL_LIST_STATS_START";
export const POOL_LIST_STATS_SUCCESS = "POOL_LIST_STATS_SUCCESS";
export const POOL_LIST_STATS_FAIL = "POOL_LIST_STATS_FAIL";

export const TOKEN_LIST_STATS_SAGA = "TOKEN_LIST_STATS_SAGA";
export const TOKEN_LIST_STATS_START = "TOKEN_LIST_STATS_START";
export const TOKEN_LIST_STATS_SUCCESS = "TOKEN_LIST_STATS_SUCCESS";
export const TOKEN_LIST_STATS_FAIL = "TOKEN_LIST_STATS_FAIL";

export const ADD_SWAP_TRANSACTION_SAGA = "ADD_SWAP_TRANSACTION_SAGA";
export const ADD_SWAP_TRANSACTION_START = "ADD_SWAP_TRANSACTION_START";
export const ADD_SWAP_TRANSACTION_SUCCESS = "ADD_SWAP_TRANSACTION_SUCCESS";
export const ADD_SWAP_TRANSACTION_FAIL = "ADD_SWAP_TRANSACTION_FAIL";

export const ADD_LIQUIDITY_TRANSACTION_SAGA = "ADD_LIQUIDITY_TRANSACTION_SAGA";
export const ADD_LIQUIDITY_TRANSACTION_START =
  "ADD_LIQUIDITY_TRANSACTION_START";
export const ADD_LIQUIDITY_TRANSACTION_SUCCESS =
  "ADD_LIQUIDITY_TRANSACTION_SUCCESS";
export const ADD_LIQUIDITY_TRANSACTION_FAIL = "ADD_LIQUIDITY_TRANSACTION_FAIL";

export const TOKEN_ADD_SAGA = "TOKEN_ADD_SAGA";
export const TOKEN_ADD_START = "TOKEN_ADD_START";
export const TOKEN_ADD_SUCCESS = "TOKEN_ADD_SUCCESS";
export const TOKEN_ADD_FAIL = "TOKEN_ADD_FAIL";

export const POOL_ADD_SAGA = "POOL_ADD_SAGA";
export const POOL_ADD_START = "POOL_ADD_START";
export const POOL_ADD_SUCCESS = "POOL_ADD_SUCCESS";
export const POOL_ADD_FAIL = "POOL_ADD_FAIL";
