/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { Button, Input, Modal } from "antd";
import React, { useEffect, useState } from "react";
import { http, createPublicClient } from "viem";
import "./model.css";
import { waitForTransactionReceipt } from "viem/actions";
import { convertToDecimal } from "../../helper/func";
import { useWriteContract, useAccount, useReadContract } from "wagmi";
import { config } from "../..";
import {
  LinkTokenAddress,
  SLTTokenAddress,
  SolulabLiquidityAddress,
  UsdcTokenAddress,
  WETHTokenAddress,
} from "../../contract/address";
import { polygonAmoy } from "viem/chains";
import {
  LinkTokenAbi,
  SolulabLiquidityTokenAbi,
  SolulabTokenAbi,
  UsdcTokenAbi,
  WETHTokenAbi,
} from "../../contract";
import { getClient } from "@wagmi/core";
import { CHECK_ICON } from "../../assets/images";
import { useDispatch } from "react-redux";
import { AddLiquidityTransaction } from "../../store/actions";
const abi = {
  [UsdcTokenAddress]: UsdcTokenAbi,
  [LinkTokenAddress]: LinkTokenAbi,
  [SLTTokenAddress]: SolulabTokenAbi,
  [WETHTokenAddress]: WETHTokenAbi,
};
const AddLiquidity = ({ isOpen, onCancel }) => {
  const [isLoading, setIsLoading] = useState();
  const { address } = useAccount();
  const dispatch = useDispatch();
  const { open, data } = isOpen;
  const [tokenOneAmount, setTokenOneAmount] = useState(null);
  const [tokenTwoAmount, setTokenTwoAmount] = useState(null);
  const [tokenOne, setTokenOne] = useState(data?.token1Details);
  const [tokenTwo, setTokenTwo] = useState(data?.token2Details);
  const [addSuccess, setAddSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  function changeAmount(e) {
    if (e.target.value) {
      setTokenOneAmount(e.target.value);
    } else {
      setTokenOneAmount(null);
      setTokenTwoAmount(null);
    }
  }
  const { writeContractAsync } = useWriteContract();
  const { data: balanceData, isPending } = useReadContract({
    functionName: "balanceOf",
    abi: SolulabTokenAbi,
    args: [address],
    address: tokenOne.address,
  });

  const handleTransfer = async () => {
    setIsLoading(true);
    const tkn = Number(+tokenOneAmount);
    const tkn2 = Number(+tokenTwoAmount);
    // eslint-disable-next-line no-undef
    if (
      Number(convertToDecimal(+tkn, +tokenOne?.decimals)) < Number(balanceData)
    ) {
      setErrorMessage("");
      try {
        const client = getClient(config);
        const allowanceHash = await writeContractAsync({
          abi: abi[tokenOne?.address],
          address: tokenOne?.address,
          functionName: "approve",
          args: [
            SolulabLiquidityAddress,
            convertToDecimal(+tkn, +tokenOne?.decimals),
          ],
        });
        await waitForTransactionReceipt(client, {
          confirmations: 1,
          hash: allowanceHash,
        });
        const allowanceHash2 = await writeContractAsync({
          abi: abi[tokenTwo?.address],
          address: tokenTwo?.address,
          functionName: "approve",
          args: [
            SolulabLiquidityAddress,
            convertToDecimal(+tkn2, +tokenTwo?.decimals),
          ],
        });
        await waitForTransactionReceipt(client, {
          confirmations: 1,
          hash: allowanceHash2,
        });

        const txHash = await writeContractAsync({
          abi: SolulabLiquidityTokenAbi,
          address: SolulabLiquidityAddress,
          functionName: "addLiquidity",
          args: [
            tokenOne?.address,
            tokenTwo?.address,
            convertToDecimal(+tkn, +tokenOne?.decimals),
            convertToDecimal(+tkn2, +tokenTwo?.decimals),
            convertToDecimal(0, +tokenOne?.decimals),
            convertToDecimal(0, +tokenTwo?.decimals),
            address,
          ],
        });
        const res = await waitForTransactionReceipt(client, {
          confirmations: 1,
          hash: txHash,
        });
        if (res.status === "success") {
          setAddSuccess(true);

          const apiData = {
            transactionHash: res.transactionHash,
            pool_id: data._id,
            accountWalletPublicAddress: address,
            token1: tokenOne._id,
            token2: tokenTwo._id,
            amountToken2: tokenTwoAmount,
            amountToken1: tokenOneAmount,
            type: "Add",
          };

          dispatch(AddLiquidityTransaction(apiData));

          setTimeout(() => {
            setAddSuccess(false);
            handleClose();
          }, 6000);
        }
      } catch (error) {
        setErrorMessage(error?.shortMessage || error?.message);
        // setTimeout(() => {
        //   setErrorMessage("");
        // }, 3000);
        console.log({ error });
      } finally {
        setIsLoading(false);
      }
    } else {
      setIsLoading(false);
      setErrorMessage("Insufficient balance,Please check.");
    }
  };
  useEffect(() => {
    const getAmount = async () => {
      try {
        const result = await createPublicClient({
          chain: polygonAmoy,
          transport: http(),
        }).readContract({
          abi: SolulabLiquidityTokenAbi,
          address: SolulabLiquidityAddress,
          functionName: "getAmountsOut",
          args: [
            tokenOne?.address,
            tokenTwo?.address,
            convertToDecimal(tokenOneAmount, +tokenOne?.decimals)?.toString(),
          ],
        });
        setTokenTwoAmount(
          Number(
            Number(Number(result) / Math.pow(10, tokenTwo?.decimals)).toFixed(6)
          )
        );
        return result;
      } catch (err) {
        console.log(err);
        return 0;
      }
    };
    getAmount();
  }, [tokenOneAmount]);

  //   if (isPending1 || isPending2) return null;

  const handleClose = () => {
    setTokenOneAmount(null);
    setTokenTwoAmount(null);
    onCancel();
  };

  return (
    <Modal
      open={open}
      footer={null}
      onCancel={handleClose}
      maskClosable={false}
      title="Add liquidity"
      style={{ paddingBottom: 0 }}
    >
      {addSuccess ? (
        <div
          style={{
            padding: "50px 0",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            gap: "10px",
          }}
        >
          <img src={CHECK_ICON} alt="me" width="80px" />

          <div className="successBoxHeader">Liquidity Added Successfully</div>
        </div>
      ) : isPending ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            padding: "100px 0",
          }}
        >
          <div className="loader"></div>
        </div>
      ) : (
        <div className="modalContent" style={{ padding: "16px " }}>
          <div className="tokenDetails">
            <div className="token">
              <img
                src={data?.token1Details.img}
                alt="assetOneLogo"
                className="assetLogo"
              />
              {data?.token1Details.ticker}
            </div>
            <div className="token">
              <img
                src={data?.token2Details.img}
                alt="assetOneLogo"
                className="assetLogo"
              />
              {data?.token2Details.ticker}
            </div>
          </div>
          <div className="inputs">
            <Input
              placeholder="0"
              value={tokenOneAmount}
              type="number"
              onChange={changeAmount}
            />
            <Input placeholder="0" value={tokenTwoAmount} disabled />

            <div className="assetOne">
              <img
                src={data?.token1Details.img}
                alt="assetOneLogo"
                className="assetLogo"
              />
              {data?.token1Details.ticker}
            </div>
            <div className="assetTwo">
              <img
                src={data?.token2Details.img}
                alt="assetOneLogo"
                className="assetLogo"
              />
              {data?.token2Details.ticker}
            </div>
            {errorMessage && (
              <div className="errorBox">ErrorMessage: {errorMessage}</div>
            )}
            <Button
              className="swapButton"
              disabled={isLoading || !tokenOneAmount || !tokenTwoAmount}
              onClick={handleTransfer}
            >
              {!tokenOneAmount ? "Add Amount" : isLoading ? "Adding..." : "Add"}
            </Button>
          </div>
        </div>
      )}
    </Modal>
  );
};

export default AddLiquidity;
