import { Routes, Route } from "react-router-dom";
import { useAccount } from "wagmi";
// import { MetaMaskConnector } from "wagmi/connectors/metaMask";
// import { WalletConnectConnector } from "wagmi/connectors/walletConnect";
import Header from "./components/Header";
import Swap from "./components/Swap";
import Tokens from "./components/Tokens";
import "./App.css";
import Pool from "./components/Pool";
import Create from "./components/CreatePoolToken";

function App() {
  const { address, isConnected } = useAccount();
  // const { connect } = useConnect({
  //   connector: [
  //     new MetaMaskConnector(),
  //     new WalletConnectConnector({ projectId: "8c963e6e044549c04fdc38419fd26a01" }),
  //   ],
  // });

  return (
    <div className="App">
      <Header isConnected={isConnected} address={address} />
      <div className="mainWindow">
        <Routes>
          <Route
            path="/"
            element={<Swap isConnected={isConnected} address={address} />}
          />
          <Route path="/tokens" element={<Tokens />} />
          <Route path="/pool" element={<Pool />} />
          <Route path="/createpool" element={<Create />} />
        </Routes>
      </div>
    </div>
  );
}

export default App;
