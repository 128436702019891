import React from "react";
import TVLChart from "./Charts/TVLChart";
import TokenTable from "./Tables/TokenTable";

function Tokens() {
  return (
    <div className="TokenList">
      <TVLChart /> <TokenTable />
    </div>
  );
}

export default Tokens;
