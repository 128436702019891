/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { Box } from "@mui/material";
import { formatNumber } from "../../helper/func";
import { Table } from "antd";
import { GetTokenList } from "../../store/actions";
import { useDispatch, useSelector } from "react-redux";

function perValue(val, v) {
  switch (v) {
    case 1:
      return (
        <div className="redText">
          <svg
            width="16"
            height="16"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            aria-label="down"
            class="sc-lizKOf fjINkT"
          >
            <path
              d="M10.6979 16.2453L6.31787 9.75247C5.58184 8.66118 6.2058 7 7.35185 7L16.6482 7C17.7942 7 18.4182 8.66243 17.6821 9.75247L13.3021 16.2453C12.623 17.2516 11.377 17.2516 10.6979 16.2453Z"
              fill="currentColor"
            ></path>
          </svg>
          {Math.abs(val).toFixed(2)} %
        </div>
      );

    case 2:
      return (
        <div className="greenText">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 24 24"
            fill="none"
            aria-label="up"
            class="sc-hTUWRQ kHJrOf"
          >
            <path
              d="M13.3021 7.7547L17.6821 14.2475C18.4182 15.3388 17.7942 17 16.6482 17L7.3518 17C6.2058 17 5.5818 15.3376 6.3179 14.2475L10.6979 7.7547C11.377 6.7484 12.623 6.7484 13.3021 7.7547Z"
              fill="currentColor"
            ></path>
          </svg>
          {Math.abs(val).toFixed(2)} %
        </div>
      );
    default:
      return;
  }
}

const columns = [
  {
    key: "no",
    dataIndex: "no",
    title: "#",
    width: 60,
  },
  {
    key: "ticker",
    dataIndex: "ticker",

    render: (_, record) => (
      <Box sx={{ display: "flex", alignItems: "center", gap: "6px" }}>
        <img src={record.img} alt="assetOneLogo" width={25} />
        <span style={{ fontWeight: 500 }}>{record.name}</span>
        <span style={{ color: "gray", fontSize: "12px" }}>{record.ticker}</span>
      </Box>
    ),
    title: "Token Name",
    width: 300,
  },
  {
    width: 120,
    key: "project",
    dataIndex: "project",
    align: "right",
    title: "Price",
    render: (_, record) => <>$ {record?.price}</>,
  },
  {
    key: "standard",
    dataIndex: "standard",

    width: 120,
    align: "right",
    title: "1 Day",
    render: (_, record) => (
      <>
        {perValue(
          record?.price_change_24h,
          record?.price_change_24h <= 0 ? 1 : 2
        )}
      </>
    ),
  },

  {
    key: "decimals",
    align: "right",
    dataIndex: "decimals",
    width: 120,
    title: "FDV",
    render: (_, record) => <>$ {formatNumber(record?.market_cap)}</>,
  },
  {
    width: 120,

    key: "chain",
    dataIndex: "chain",

    align: "right",
    title: "Volume",
    render: (_, record) => <>$ {formatNumber(record?.volume_24h)}</>,
  },
];

const TokenTable = () => {
  const dispatch = useDispatch();
  const { tokenListData, isTokenFetchLoading } = useSelector(
    (state) => state.token
  );

  useEffect(() => {
    dispatch(GetTokenList());
  }, []);
  return (
    <div className="">
      {/* <TVLChart />{" "} */}
      <Table
        loading={isTokenFetchLoading}
        columns={columns}
        // dataSource={tokenListData}
        pagination={false} // Disable pagination
        dataSource={tokenListData.map((item, i) => ({ no: i + 1, ...item }))}
      />
    </div>
  );
};

export default TokenTable;
