import { Close } from "@mui/icons-material";
import {
  Backdrop,
  Box,
  Fade,
  IconButton,
  Modal,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useChainId, useConnect, useSignMessage } from "wagmi";
import { METAMASK_ICON, WALLET_ICON } from "../../assets/images";
import { ConnectorButton } from "../../helper/helper";
// import { IoMdClose } from "react-icons/io";
// import "./style.scss";

const walletData = {
  MetaMask: {
    name: "Metamask",
    icon: METAMASK_ICON,
  },
  WalletConnect: {
    name: "Wallet Connect",
    icon: WALLET_ICON,
  },
};

const ConnectWalletModal = ({ open, title, subTitle, setIsconnect }) => {
  const chainId = useChainId();
  const [isConnecting, setIsConnecting] = useState(false);
  const { connectors, connectAsync } = useConnect();
  const { signMessageAsync } = useSignMessage();
  const connectWallet = async (connector, chainId) => {
    try {
      setIsConnecting(true);
      const addressList = await connectAsync({ connector, chainId });
      // Set Selected Provider in local storage
      localStorage.setItem("activeWalletProvider", connector?.name);
      // get Signature for first account only
      const result = await signMessageAsync({ message: "Signature" });
      console.log({ addressList, result });
      setIsConnecting(false);
      setIsconnect(false);
    } catch (err) {
      console.log({ err });
      setIsConnecting(false);
    }
  };
  return (
    <Modal
      open={open}
      closeAfterTransition
      TransitionComponent={Fade}
      BackdropComponent={Backdrop}
      BackdropProps={{
        sx: {
          backdropFilter: "blur(8px)",
        },
      }}
    >
      <Box
        className="modal_container"
        sx={{
          width: { xs: "100%", sm: "430px", xl: "375px" },
          background: "#323232",
        }}
      >
        <IconButton
          aria-label="close"
          onClick={() => setIsconnect(false)}
          className="close_icon"
        >
          <Close />
        </IconButton>
        {isConnecting && <div>Connecting...</div>}
        <Typography
          variant="h5"
          fontWeight={700}
          color="var(--text-primary)"
          textAlign="center"
        >
          {title}
        </Typography>
        <Typography
          variant="body2"
          fontWeight={500}
          mt="10px"
          color="var(--text-secondary)"
          textAlign="center"
        >
          {subTitle}
        </Typography>
        <Box gap="8px" display="grid">
          {connectors.map((connector) => (
            <ConnectorButton
              key={connector.uid}
              connector={connector}
              icon={walletData[connector?.name]?.icon}
              name={walletData[connector?.name]?.name}
              onClick={() => connectWallet(connector, chainId)}
            />
          ))}
        </Box>
      </Box>
    </Modal>
  );
};

export default ConnectWalletModal;
