import * as actionLabels from "../../actionLabels";

export const initialState = {
  isLoading: false,
  isTokenFetchLoading: false,
  isAddTransLoading: false,
  isPoolDataFetching: false,
  isAddLiquiFetching: false,
  errorMsg: "",
  tokenData: [],
  poolData: [],
  tokenListData: [],
  transactionDetails: null,
  AddLiquitransactionDetails: null,

  isTokenAddLoading: false,
  TokenAdd: null,
  addTokenErr: "",
  isPoolAddLoading: false,
  PoolAddData: null,
  poolErr: "",
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case actionLabels.SET_DEFAULT:
      return { ...state, isLogin: false };

    case actionLabels.TOKEN_STATS_START:
      return { ...state, isLoading: true };

    case actionLabels.TOKEN_STATS_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        tokenData: payload,
        errorMsg: "",
      };
    }
    case actionLabels.TOKEN_STATS_FAIL: {
      return { ...state, isLoading: false, errorMsg: payload };
    }

    // TOKEN LIST
    case actionLabels.TOKEN_LIST_STATS_START:
      return { ...state, isTokenFetchLoading: true };

    case actionLabels.TOKEN_LIST_STATS_SUCCESS: {
      return {
        ...state,
        isTokenFetchLoading: false,
        tokenListData: payload,
        errorMsg: "",
      };
    }
    case actionLabels.TOKEN_LIST_STATS_FAIL: {
      return { ...state, isTokenFetchLoading: false, errorMsg: payload };
    }

    // POOL LIST
    case actionLabels.POOL_LIST_STATS_START:
      return { ...state, isPoolDataFetching: true };

    case actionLabels.POOL_LIST_STATS_SUCCESS: {
      return {
        ...state,
        isPoolDataFetching: false,
        poolData: payload,
        errorMsg: "",
      };
    }
    case actionLabels.POOL_LIST_STATS_FAIL: {
      return { ...state, isPoolDataFetching: false, errorMsg: payload };
    }

    // SWAP TOKEN
    case actionLabels.ADD_SWAP_TRANSACTION_START:
      return { ...state, isAddTransLoading: true };

    case actionLabels.ADD_SWAP_TRANSACTION_SUCCESS: {
      return {
        ...state,
        isAddTransLoading: false,
        transactionDetails: payload,
        errorMsg: "",
      };
    }
    case actionLabels.ADD_SWAP_TRANSACTION_FAIL: {
      return { ...state, isAddTransLoading: false, errorMsg: payload };
    }

    // Add TOKEN
    case actionLabels.TOKEN_ADD_START:
      return { ...state, isTokenAddLoading: true };

    case actionLabels.TOKEN_ADD_SUCCESS: {
      return {
        ...state,
        isTokenAddLoading: false,
        TokenAdd: payload,
        addTokenErr: "",
      };
    }
    case actionLabels.TOKEN_ADD_FAIL: {
      return { ...state, isTokenAddLoading: false, addTokenErr: payload };
    }
    // Add TOKEN
    case actionLabels.POOL_ADD_START:
      return { ...state, isPoolAddLoading: true };

    case actionLabels.POOL_ADD_SUCCESS: {
      return {
        ...state,
        isPoolAddLoading: false,
        PoolAddData: payload,
        poolErr: "",
      };
    }
    case actionLabels.POOL_ADD_FAIL: {
      return { ...state, isPoolAddLoading: false, poolErr: payload };
    }

    // SWAP TOKEN
    case actionLabels.ADD_LIQUIDITY_TRANSACTION_START:
      return { ...state, isAddLiquiFetching: true };

    case actionLabels.ADD_LIQUIDITY_TRANSACTION_SUCCESS: {
      return {
        ...state,
        isAddLiquiFetching: false,
        AddLiquitransactionDetails: payload,
        errorMsg: "",
      };
    }
    case actionLabels.ADD_LIQUIDITY_TRANSACTION_FAIL: {
      return { ...state, isAddLiquiFetching: false, errorMsg: payload };
    }
    default:
      return state;
  }
};
