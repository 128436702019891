/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable import/prefer-default-export */
import { delay, put } from "redux-saga/effects";
import axiosMain from "../../../http/axios/axios_main";

import {
  AddSwapTransactionFail,
  AddSwapTransactionStart,
  AddSwapTransactionSuccess,
  GetTokenListFail,
  GetTokenListStart,
  GetTokenListSuccess,
  tokenStatsFail,
  tokenStatsStart,
  tokenStatsSuccess,
  GetPoolListStart,
  GetPoolListFail,
  GetPoolListSuccess,
  AddLiquidityTransactionStart,
  AddLiquidityTransactionSuccess,
  AddLiquidityTransactionFail,
  AddTokenStart,
  AddTokenSuccess,
  AddTokenFail,
  GetPoolList,
  PoolAddStart,
  PoolAddSuccess,
  PoolAddFail,
} from "../../actions/Token/Token";

export function* tokenStatsSaga(action) {
  yield put(tokenStatsStart());
  try {
    const response = yield axiosMain.get(`/token`);
    if (response.status === 200) {
      yield put(tokenStatsSuccess(response.data.data));
    } else {
      yield put(tokenStatsFail("Something went wrong! Please try again."));
    }
  } catch (error) {
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(tokenStatsFail(error.response.data.msg));
      } else if (
        error.response.data.msg !== undefined &&
        error.response.data.msg !== "" &&
        typeof error.response.data.msg === "string"
      ) {
        yield put(tokenStatsFail(error.response.data.msg));
      } else {
        yield put(tokenStatsFail("Something went wrong! Please try again."));
      }
    } else {
      yield put(tokenStatsFail("Something went wrong! Please try again."));
    }
  }
}

export function* tokenAddSaga({ payload }) {
  yield put(AddTokenStart());
  try {
    const response = yield axiosMain.post(`/token`, payload);
    if (response.status === 200) {
      yield put(AddTokenSuccess(response.data.data));
    } else {
      yield put(AddTokenFail("Something went wrong! Please try again."));
    }
  } catch (error) {
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400 || 422) {
        yield put(AddTokenFail(error.response.data.message));
        yield delay(5000); // 5-second delay
        yield put(PoolAddFail(""));
      } else if (
        error.response.data.message !== undefined &&
        error.response.data.message !== "" &&
        typeof error.response.data.message === "string"
      ) {
        yield put(AddTokenFail(error.response.data.message));
      } else {
        yield put(AddTokenFail("Something went wrong! Please try again."));
      }
    } else {
      yield put(AddTokenFail("Something went wrong! Please try again."));
    }
  }
}
export function* poolAddSaga({ payload }) {
  yield put(PoolAddStart());
  try {
    const response = yield axiosMain.post(`/pool/tokenpool`, payload);
    if (response.status === 200) {
      yield put(PoolAddSuccess(response.data.data));
    } else {
      yield put(PoolAddFail("Something went wrong! Please try again."));
    }
  } catch (error) {
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400 || 422) {
        yield put(PoolAddFail(error.response.data.message));
        yield delay(5000); // 5-second delay
        yield put(PoolAddFail(""));
      } else if (
        error.response.data.message !== undefined &&
        error.response.data.message !== "" &&
        typeof error.response.data.message === "string"
      ) {
        yield put(PoolAddFail(error.response.data.message));
      } else {
        yield put(PoolAddFail("Something went wrong! Please try again."));
      }
    } else {
      yield put(PoolAddFail("Something went wrong! Please try again."));
    }
  }
}

export function* tokenListSaga(action) {
  yield put(GetTokenListStart());
  try {
    const response = yield axiosMain.get(`/tokenprice`);
    if (response.status === 200) {
      yield put(GetTokenListSuccess(response.data.data));
    } else {
      yield put(GetTokenListFail("Something went wrong! Please try again."));
    }
  } catch (error) {
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(GetTokenListFail(error.response.data.msg));
      } else if (
        error.response.data.msg !== undefined &&
        error.response.data.msg !== "" &&
        typeof error.response.data.msg === "string"
      ) {
        yield put(GetTokenListFail(error.response.data.msg));
      } else {
        yield put(GetTokenListFail("Something went wrong! Please try again."));
      }
    } else {
      yield put(GetTokenListFail("Something went wrong! Please try again."));
    }
  }
}

export function* poolListSaga(action) {
  yield put(GetPoolListStart());
  try {
    const response = yield axiosMain.get(`/pool`);
    if (response.status === 200) {
      yield put(GetPoolListSuccess(response.data.data));
    } else {
      yield put(GetPoolListFail("Something went wrong! Please try again."));
    }
  } catch (error) {
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(GetPoolListFail(error.response.data.msg));
      } else if (
        error.response.data.msg !== undefined &&
        error.response.data.msg !== "" &&
        typeof error.response.data.msg === "string"
      ) {
        yield put(GetPoolListFail(error.response.data.msg));
      } else {
        yield put(GetPoolListFail("Something went wrong! Please try again."));
      }
    } else {
      yield put(GetPoolListFail("Something went wrong! Please try again."));
    }
  }
}

export function* AddSwapTransactionSaga({ payload }) {
  yield put(AddSwapTransactionStart());
  try {
    const response = yield axiosMain.post(`/transaction`, payload);
    if (response.status === 200) {
      yield put(AddSwapTransactionSuccess(response.data.data));
    } else {
      yield put(
        AddSwapTransactionFail("Something went wrong! Please try again.")
      );
    }
  } catch (error) {
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(AddSwapTransactionFail(error.response.data.msg));
      } else if (
        error.response.data.msg !== undefined &&
        error.response.data.msg !== "" &&
        typeof error.response.data.msg === "string"
      ) {
        yield put(AddSwapTransactionFail(error.response.data.msg));
      } else {
        yield put(
          AddSwapTransactionFail("Something went wrong! Please try again.")
        );
      }
    } else {
      yield put(
        AddSwapTransactionFail("Something went wrong! Please try again.")
      );
    }
  }
}

export function* AddLiquiTransactionSaga({ payload }) {
  yield put(AddLiquidityTransactionStart());
  try {
    const response = yield axiosMain.post(`/liquidity`, payload);
    if (response.status === 200) {
      yield put(AddLiquidityTransactionSuccess(response.data.data));
      yield put(GetPoolList());
    } else {
      yield put(
        AddLiquidityTransactionFail("Something went wrong! Please try again.")
      );
    }
  } catch (error) {
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(AddLiquidityTransactionFail(error.response.data.msg));
      } else if (
        error.response.data.msg !== undefined &&
        error.response.data.msg !== "" &&
        typeof error.response.data.msg === "string"
      ) {
        yield put(AddLiquidityTransactionFail(error.response.data.msg));
      } else {
        yield put(
          AddLiquidityTransactionFail("Something went wrong! Please try again.")
        );
      }
    } else {
      yield put(
        AddLiquidityTransactionFail("Something went wrong! Please try again.")
      );
    }
  }
}
